import React from 'react';
import userProvider from '../provider/UserProvider';
import { useQuery } from 'react-query';
import Loading from '../pages/Loading';

const BoxPage = () => {
    const { data: genrePourcentage, isLoading: genrePourcentageLoading, isError: genrePourcentageError } =
        useQuery('getGenrePourcentage', userProvider.showGenderPercentage);
    const { data: artistePourcentage, isLoading: artistePourcentageLoading, isError: artistePourcentageError } =
        useQuery('getArtistePourcentage', userProvider.showEcrivainLecteur);
    return (
        <div className='container-fluid h-100'>
            <ul className="list-unstyled d-flex flex-row justify-content-around h-100">
                <li className='d-flex align-items-center' style={{ width: '20%' }} >
                    {
                        artistePourcentageError ? <></> :
                            artistePourcentageLoading ? <Loading /> :
                                <div className="container-fluid bg-body rounded-3 shadow-sm" style={{ height: '60%' }}>
                                    <div className="row" style={{ height: '30%' }}>
                                        <p className="fs-6 fw-bold"> {artistePourcentage['labels'][0]} :</p>
                                    </div>
                                    <div className="row">
                                        <p className="text-center fs-1" style={{ fontWeight: '800' }}>{artistePourcentage['data'][0].toFixed(2)}%</p>
                                        <p className="text-start fs-5" style={{ fontWeight: '500' }}></p>
                                    </div>
                                </div>}
                </li>
                <li className='d-flex align-items-center' style={{ width: '20%' }} >
                    {
                        artistePourcentageError ? <></> :
                            artistePourcentageLoading ? <Loading /> :
                                <div className="container-fluid bg-body rounded-3 shadow-sm" style={{ height: '60%' }}>
                                    <div className="row" style={{ height: '30%' }}>
                                        <p className="fs-6 fw-bold"> {artistePourcentage['labels'][1]} :</p>
                                    </div>
                                    <div className="row">
                                        <p className="text-center fs-1" style={{ fontWeight: '800' }}>{artistePourcentage['data'][1].toFixed(2)}%</p>
                                        <p className="text-start fs-5" style={{ fontWeight: '500' }}></p>
                                    </div>
                                </div>}
                </li>
                <li className='d-flex align-items-center' style={{ width: '20%' }} >
                    {
                        genrePourcentageError ? <></> :
                            genrePourcentageLoading ? <Loading /> :
                                <div className="container-fluid bg-body rounded-3 shadow-sm" style={{ height: '60%' }}>
                                    <div className="row" style={{ height: '30%' }}>
                                        <p className="fs-6 fw-bold"> Hommes :</p>
                                    </div>
                                    <div className="row">
                                        <p className="text-center fs-1" style={{ fontWeight: '800' }}>{genrePourcentage['data'][0].toFixed(2)}%</p>
                                        <p className="text-start fs-5" style={{ fontWeight: '500' }}></p>
                                    </div>
                                </div>}
                </li>
                <li className='d-flex align-items-center' style={{ width: '20%' }} >
                    {
                        genrePourcentageError ? <></> :
                            genrePourcentageLoading ? <Loading /> :
                                <div className="container-fluid bg-body rounded-3 shadow-sm" style={{ height: '60%' }}>
                                    <div className="row" style={{ height: '30%' }}>
                                        <p className="fs-6 fw-bold"> Femmes :</p>
                                    </div>
                                    <div className="row">
                                        <p className="text-center fs-1" style={{ fontWeight: '800' }}>{genrePourcentage['data'][1].toFixed(2)}%</p>
                                        <p className="text-start fs-5" style={{ fontWeight: '500' }}></p>
                                    </div>
                                </div>}
                </li>

            </ul>
        </div>
    );
};

export default BoxPage;