import React, { useEffect } from 'react';
import SideBar from './SideBar';
import ChartUser from '../components/ChartUser';
import Diversite from '../components/Diversite';
import { useNavigate } from 'react-router-dom';
import BoxPage from '../components/BoxPage';


const Home = () => {
    // const { data : totalUser, isLoading : totalUserLoading, isError : totalUserError } =
    //  useQuery('status&genreOfUser', UserProvider.getTolalUser);

    const navigate = useNavigate();
    useEffect(() => {
        const token = localStorage.getItem('token');
        if (!token) {
            navigate('/login');
        }
    });
    return (
        <div className='container-fluid'>
            <div className="row">
                <div className="col-2 p-0">
                    <SideBar />
                </div>
                <div className="col-10">
                    <div className="row d-flex aling-items-center" style={{ height: '35vh' }}>
                        <BoxPage />
                    </div>
                    <div className="row" style={{ height: '65vh' }}>
                        <div className="col-8 d-flex align-items-center bg-body shadow-sm rounded-3 ">
                            <ChartUser />
                        </div>
                        <div className="col-4">
                            <Diversite />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Home;