import React, { useState } from 'react';
import { useQuery } from 'react-query';
import Loading from '../pages/Loading';
import apiProvider from '../provider/BookProvider';

const PalmaresBookList = () => {
    const { data, isLoading, isError } = useQuery("bookFilter", apiProvider.getBookPalmares);
    const genres = ["Roman", "Bd", "DevPers", "Poème", "Nouvelle"];
    const [number, setNumber] = useState(10);
    const [selectedGenre, setSelectedGenre] = useState("");
    const [selectedOption, setSelectedOption] = useState("viewNumber");
    const [selectedBooks, setSelectedBooks] = useState([]);
    const filterBooks = () => {
        let filteredBooks = [...data];
        if (selectedGenre !== '') {
            filteredBooks = filteredBooks.filter((book) => book.genre === selectedGenre);
        }
        if (selectedOption === 'viewsNumber') {
            filteredBooks.sort((a, b) => b.viewsNumber - a.viewsNumber);
        } else if (selectedOption === 'likeNumber') {
            filteredBooks.sort((a, b) => b.likeNumber - a.likeNumber);
        }
        filteredBooks = filteredBooks.slice(0, number);
        setSelectedBooks(filteredBooks);
    }
    if (isError) {
        return (
            <div className="container-fluid">
                <h1>Une erreur s'est produite!</h1>
            </div>
        );
    }
    if (isLoading) {
        return (
            <div className="container-fluid d-flex justify-content-center align-items-center h-100" style={{ height: '100%' }}>
                <Loading />
            </div>
        );
    }
    return (
        <div className="container-fluid">
            <div className="row h-10  mt-10 rounded-3 bg-body shadow-sm">
                <div className="col-1">
                    <input className='form-control' type="number" id="number" min="1" max="100" defaultValue={number} style={{ color: '#111' }} onChange={(e) => setNumber(e.target.value)} />
                </div>
                <div className="col-7">
                    <ul className='list-unstyled d-flex flex-row align-items-center justify-content-around h-100'>
                        {genres.map((genre, index) => (
                            <li key={index}>
                                <input type="radio" name="inputRadio" id={genre} value={genre} onChange={(e) => setSelectedGenre(e.target.value)} />
                                <label htmlFor={genre}>{genre}</label>
                            </li>
                        ))}
                    </ul>
                </div>
                <div className="col-2 d-flex align-items-center">
                    <select className='form-control' type="select" defaultValue={selectedOption} style={{ color: '#111' }} onChange={(e) => { setSelectedOption(e.target.value) }} >
                        <option value="">Filter type</option>
                        <option value="likeNumber">likeNumber</option>
                        <option value="viewsNumber">viewsNumber</option>
                    </select>
                </div>
                <div className="col-2 d-flex align-items-center">
                    <button type='button' className='btn btn-primary' onClick={filterBooks}>Executer</button>
                </div>
            </div>
            <div className="scrollable-list row" style={{ maxHeight: '60vh', overflowY: 'auto' }} id='bookList'>
                <ul className="list-unstyled">
                    {selectedBooks
                        .map((book, index) => (
                            <li className='d-flex align-items-center mt-1' key={index}>
                                <div className="container-fluid rounded-3 bg-body shadow-sm h-8">
                                    <div className="row">
                                        <div className="col-3">
                                            <p className="text-start">
                                                {book.title}
                                            </p>
                                        </div>
                                        <div className="col-3">
                                            <p className="text-start text-primary">
                                                {book.author}
                                            </p>
                                        </div>
                                        <div className="col-2">
                                            <p className="text-center">
                                                {book.genre}
                                            </p>
                                        </div>
                                        <div className="col-2">
                                            <p className="text-center">
                                                {`${(selectedOption !== 'Tous') ? selectedOption : 'viewsNumber'} : ${(selectedOption === 'likeNumber') ? book.likeNumber : book.viewsNumber}`}
                                            </p>
                                        </div>
                                        <div className="col-2">
                                            <p className="text-center">
                                                {book.language}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </li>
                        ))}
                </ul>
            </div>
        </div>
    );
};

export default PalmaresBookList;