import BASE_URL from "../utils/constant";
const token = localStorage.getItem('token');

class UserProvider {
    async getTotalUsers() {
        try {
            const url = `${BASE_URL}/totalUsers'`;
            const response = await fetch(url, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            return response.json();
        } catch (error) {
            console.error('Error:', error.message);
            throw error;
        }
    }

    async getUserStats(month, day = null) {
        try {
            const url = `${BASE_URL}/user-stats/${month}${day ? `/${day}` : ''}`;
            const response = await fetch(url, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            return await response.json();
        } catch (error) {
            console.error('Error:', error.message);
            throw error;
        }
    }

    async showGenderPercentage() {
        try {
            const url = `${BASE_URL}/get-gender-stat`;
            const response = await fetch(url, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            return await response.json();
        } catch (error) {
            console.error('Error:', error.message);
            throw error;
        }
    }

    async ageDiversity() {
        try {
            const url = `${BASE_URL}/age-diversity`;
            const response = await fetch(url, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            return response.json();
        } catch (error) {
            console.error('Error:', error.message);
        }
    }

    async showEcrivainLecteur() {
        try {
            const url = `${BASE_URL}/show_ecrivain_lecteur`;
            const response = await fetch(url, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            return await response.json();
        } catch (error) {
            console.error('Error:', error.message);
            throw error;
        }
    }

    async getCurrentTransactions() {
        try {
            const url = `${BASE_URL}/current-transaction`;
            const response = await fetch(url,
                {
                    method: 'GET',
                    headers:
                    {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`
                    }
                });
            if (response.status !== 200) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            return await response.json();
        }
        catch (error) {
            console.log('Error:', error.message);
            throw error;
        }
    }

    async getAllTransactions() {
        try {
            const url = `${BASE_URL}/all-transaction`;
            const response = await fetch(url,
                {
                    method: 'GET',
                    headers:
                    {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer: ${token}`
                    }
                });
            if (!response.ok) {
                throw new Error(`HHTP error! Status: ${response.status}`);
            }
            return await response.json();
        }
        catch (error) {
            console.log('Error:', error.message);
        }
    }
    async getTolalUser() {
        try {
            const url = `${BASE_URL}/totalUsers`;
            const response = await fetch(url,
                {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`
                    }
                });
            if (!response.ok) {
                throw new Error(`http error ! status ${response.status}`);
            }
            return response.json();
        }
        catch (e) {
            console.log('Error:', e.message);
        }
    }
    async getLogsData(month = null, year = null) {
        try {
            let url = `${BASE_URL}/log-stats`;
            if (month && year) {
                url += `/${month + 1}/${year}`;
            } else if (month || year) {
                throw new Error("Both of year and month need to be provided or none!");
            }
            const response = await fetch(url,
                {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`
                    }
                }
            );
            if (!response.ok) {
                throw new Error(`HTTP error status code : ${response.status}`);
            }
            return response.json();
        } catch (e) {
            throw new Error(`Server error ${e}`);
        }
    }
}

const userProvider = new UserProvider();

export default userProvider;
