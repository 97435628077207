import React from 'react';
import SideBar from './SideBar';
import Credit from '../components/Credit';
import HistoriqueAchat from '../components/HistoriqueAchat';
// import CreditStatGlogal from '../components/CreditStatGlobal';

const GestionCreditPage = () => {
    return (
        <div className='container-fluid h-100 '>
            <div className="row">
                <div className="col-2 p-0">
                    <SideBar />
                </div>
                <div className="col-10 ">
                    <div className="container-fluid h-100">
                        <div className="row h-50">
                            <div className="">
                                <Credit />
                            </div>
                            {/* <div className="col-3 p-0 d-flex align-items-end mb-1">
                                <CreditStatGlogal />
                            </div> */}
                        </div>
                        <div className="row h-50">
                            <div className="container-fluid">
                                <HistoriqueAchat />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default GestionCreditPage;