import React, { useEffect, useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import { useQuery } from 'react-query';
import ApiProvider from '../provider/BookProvider';

const BookChart = () => {
    const [dataKeys, setDataKeys] = useState([]);
    const [dataBooks, setDataBooks] = useState([]);
    const months = ['Janvier', 'Fevrier', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Aout', 'Septembre', 'Octobre', 'Novembre', 'Decembre'];
    const currentDate = new Date();
    const currentMonth = currentDate.getMonth();
    const [selectedMonth, setSelectedMonth] = useState(currentMonth);
    const [selectedYear, setSelectedYear] = useState(currentDate.getFullYear());
    const [selectedDate, setSelectedDate] = useState("");
    const [initialFetch, setInitialFetch] = useState(true);

    const { data: books, isError, refetch: refetchBook } = useQuery(
        ["fetchBookData", selectedMonth, selectedYear],
        () => ApiProvider.getBookCreatedAt(selectedMonth, selectedYear),
        {
            enabled: initialFetch,
            onSuccess: (data) => {
                processData(data);
                setInitialFetch(false);
            },
        }
    );

    useEffect(() => {
        if (initialFetch) {
            refetchBook();
        }
    }, [initialFetch, refetchBook]);

    const processData = (books) => {
        if (books && books['books_created']) {
            setDataKeys(Object.keys(books['books_created']));
            setDataBooks(Object.values(books['books_created']));
        }
    };

    useEffect(() => {
        if (books) {
            processData(books);
        }
    }, [books]);

    useEffect(() => {
        setState((prevState) => ({
            ...prevState,
            series: [{ name: 'Oeuvres', data: dataBooks }],
            options: {
                ...prevState.options,
                xaxis: {
                    categories: dataKeys.map(key =>
                        key.substring(8)
                        // `/` + selectedMonth < 10 ? `0${selectedMonth}` : selectedMonth +
                        // '/' + selectedYear
                    )
                },
                yaxis: { tickAmount: Math.max(...dataBooks) }
            },
        }));
    }, [dataBooks, dataKeys, selectedMonth, selectedYear]);

    const handleChange = async () => {
        await refetchBook();
        setSelectedDate(`${months[selectedMonth]}/${selectedYear}`);
    };
    const [state, setState] = useState({
        series: [{
            name: 'Oeuvres',
            data: []
        }],
        options: {
            chart: {
                type: 'bar',
                height: 350
            },
            plotOptions: {
                bar: {
                    horizontal: false,
                    columnWidth: '55%',
                    endingShape: 'rounded'
                },
            },
            dataLabels: {
                enabled: false
            },
            stroke: {
                show: true,
                width: 2,
                colors: ['transparent']
            },
            xaxis: {
                categories: [],
            },
            yaxis: {
                tickAmount: 2
            },
            fill: {
                opacity: 1
            },
            tooltip: {
                y: {
                    formatter: function (val) {
                        return val;
                    }
                }
            }
        }
    });

    if (isError) {
        return <div>Error loading data...</div>;
    }

    return (
        <div className='container-fluid'>
            <div className='row bg-body' id="chart">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-2 d-flex align-items-center">
                            <p className="fs-5 text-primary text-center" style={{ fontWeight: '600' }}>{selectedDate ? selectedDate : `${months[currentMonth]} / ${currentDate.getFullYear()}`}</p>
                        </div>
                        <div className="col-2 offset-4 d-flex align-items-center">
                            <select className="form-select bg-light" defaultValue={months[selectedMonth]} onChange={(e) => setSelectedMonth(e.target.selectedIndex)}>
                                {months.map((month, index) => (
                                    <option key={index} value={month}>{month}</option>
                                ))}
                            </select>
                        </div>
                        <div className="col-2 d-flex align-items-center">
                            <input className="form-control bg-light" type="number" id="number" min="0" max="3000" defaultValue={selectedYear} style={{ height: '35px', borderRadius: '15' }} onChange={(e) => setSelectedYear(e.target.value)} />
                        </div>
                        <div className="col-1 d-flex align-items-center">
                            <button type='button' className='btn btn-primary' onClick={handleChange}>Executer</button>
                        </div>
                    </div>
                </div>
                <ReactApexChart options={state.options} series={state.series} type="bar" height={350} />
            </div>
        </div>
    );
};

export default BookChart;
