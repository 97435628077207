import React, { useState } from 'react';
import SideBar from './SideBar';
import { Table } from 'react-bootstrap';
import { useQuery } from 'react-query';
import ApiProvider from '../provider/BookProvider';
import { FaSearch } from "react-icons/fa";
import { MdOutlineSearchOff } from "react-icons/md";
import CircleLoading from '../loading/circleLoading';

const CompetitionPage = () => {
    const [search, setSearch] = useState('');
    const [searchOpen, setSearchOpen] = useState(false);
    const { data: searchData, isError: searchError, isSuccess, refetch: reSearching } = useQuery(
        ["SearchingBook", search],
        () => ApiProvider.search(search)
    );
    const [searchLoading, setSearchLoading] = useState(false);
    const { data: bookOn, refetch: refetchCompetition } = useQuery("fetchAllOnCompetition",
        ApiProvider.getAllOnCompetition);
    const handleSubmit = async (e) => {
        e.preventDefault();
        setSearchLoading(true);
        await reSearching();
        setSearchLoading(false);
    }

    const isInCompetition = (book_id) => {
        return bookOn && bookOn.some(book => book.id === book_id);
    }
    const removeOnCompetition = async (id) => {
        const confirm = window.confirm("Confirmer le retrait de l'oeuvre!");
        if (confirm) {
            await ApiProvider.removeOnCompetition(id);
            refetchCompetition();
        }

    }

    const AddOnCompetition = async (id) => {
        const confirm = window.confirm("Confirmer l'ajout de l'oeuvre!");
        if (confirm) {
            await ApiProvider.AddOnCompetition(id);
            reSearching();
            refetchCompetition();
        }
    }

    if (searchError) {
        console.log("error");
    }
    if (bookOn) {
        console.log(bookOn);
    }
    return (
        <div className='container-fluid'>
            {
                <div className="row">
                    <div className="col-2 p-0">
                        <SideBar />
                    </div>
                    <div className="col-10">
                        <div className="container-fluid">
                            <form onSubmit={handleSubmit}>
                                <div className="row">
                                    <div className="col-2 offset-1">
                                        <button className={(searchOpen && isSuccess) ?
                                            "btn btn-outline-dark my-2" :
                                            "btn btn-outline-primary my-2"}
                                            onClick={() => setSearchOpen(false)}>En competition</button>
                                    </div>
                                    <div className='col-1 offset-3'>
                                        {
                                            searchLoading &&
                                            <CircleLoading />
                                        }
                                    </div>
                                    <div className="col-4 ">
                                        <input className={`form-control my-2`} onChange={(e) => setSearch(e.target.value)} placeholder="Recherche" />
                                    </div>
                                    <div className="col-1">
                                        <button className={(searchOpen && isSuccess) ?
                                            "btn btn-outline-primary my-2" :
                                            (searchOpen && search && searchError) ?
                                                "btn btn-outline-danger my-2" :
                                                "btn btn-outline-dark my-2"
                                        } type='submit' onClick={() => setSearchOpen(true)}>
                                            {
                                                (searchOpen && search && searchError) ?
                                                    <MdOutlineSearchOff /> :
                                                    <FaSearch />}
                                        </button>
                                    </div>

                                </div>
                            </form>

                        </div>
                        <div style={{ maxHeight: '90vh', overflowY: 'auto' }} className="table-responsive">
                            <Table striped bordered hover >
                                <thead>
                                    <th>Titre</th>
                                    <th>Auteur</th>
                                    <th>Genre</th>
                                </thead>
                                <tbody >
                                    {
                                        ((searchOpen && searchData) ? searchData : ((bookOn && bookOn.length > 0) ? bookOn :
                                            [{ id: '1', title: 'Aucune oeuvre trouvée!' }]))
                                            // .filter(data => {
                                            //     return search.toLowerCase() === '' ? data :
                                            //         (data.title.toLowerCase().includes(search) || data.author.toLowerCase().includes(search) || data.genre.toLowerCase().includes(search))
                                            // })
                                            .map((data) => (
                                                <tr key={data.id} >
                                                    <td>{!searchLoading && data.title}</td>
                                                    <td>{!searchLoading && data.author}</td>
                                                    <td>{!searchLoading && data.genre}</td>
                                                    <td> {
                                                        (((bookOn && bookOn.length > 0) || (searchOpen && searchData)) && !searchLoading) &&
                                                        <button className={(isSuccess && searchOpen) ? 'btn btn-outline-success' : 'btn btn-outline-danger'}
                                                            disabled={searchOpen && isSuccess && isInCompetition(data.id)}
                                                            onClick={
                                                                async () => {
                                                                    if (isSuccess && searchOpen) {
                                                                        await AddOnCompetition(data.id);
                                                                    } else {
                                                                        await removeOnCompetition(data.id);
                                                                        await new Promise(resolve => setTimeout(resolve, 0));
                                                                        refetchCompetition();
                                                                    }
                                                                }
                                                            }
                                                        >{isSuccess && searchOpen && isInCompetition(data.id) ? 'Ajouté' : (isSuccess && searchOpen) ? 'Ajouter' : 'Retirer'}
                                                        </button>} </td>
                                                </tr>
                                            ))
                                    }
                                </tbody>
                            </Table>
                        </div>
                    </div>
                </div>}
        </div>
    );
};

export default CompetitionPage;